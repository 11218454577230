import React, {useEffect} from 'react'
import { Link } from 'gatsby'
import ScrollAnimation from 'react-animate-on-scroll';
import { Row, Col } from 'react-bootstrap'
import PortfoliosJSON from "../content/portfolios.json"
import lottie from 'lottie-web';
import WirelessIcon from "../../static/animated-icons/wireless.json"
import AiIcon from "../../static/animated-icons/ai.json"
import CyberIcon from "../../static/animated-icons/cybersecurity.json"
import MaritimeIcon from "../../static/animated-icons/transportation.json"
import EnvironmentalIcon from "../../static/animated-icons/environmental.json"
import ServiceIcon from "../../static/animated-icons/services.json"

const portfolioList = {
  wireless: WirelessIcon,
  ai: AiIcon,
  cybersecurity: CyberIcon,
  transportation: MaritimeIcon,
  environmental: EnvironmentalIcon,
  services: ServiceIcon
}

export default function MobilePortfolios() {
  useEffect(() => {
    for(let [name, icon] of Object.entries(portfolioList)) {
      let animatedIcon = lottie.loadAnimation({
        name,
        container: document.querySelector(`#${name}-icon`),
        animationData: icon,
        renderer: 'svg',
        loop: false,
        autoplay: false
      })

      animatedIcon.addEventListener('loopComplete', e => {
        lottie.pause(name)
      })
    }
  }, [])

  return (
    <>
    {PortfoliosJSON.portfolios.map((info, key) => (
        <ScrollAnimation animateIn="animate__fadeIn" duration={1.5} animateOnce={true} key={key}>
          <Row className='justify-content-between' style={{marginTop: (key === 0 ? '5rem' : "8rem")}}>
            <Col lg={5}>
              <Link className="view-more" to={info.link} style={{textDecoration: "none"}}>
                <div className="card-hover">
                  <div className="content">
                      <div 
                          className='animated-icon' 
                          id={info.shortName + "-icon"}
                          onMouseEnter={() => lottie.play(info.shortName)}
                      ></div>
                      <div className="description">
                          <p className="title" style={{color: '#242943'}}>
                          <strong>{info.title}</strong>
                          </p>
                          <ul className='info'>
                          {info.items.map((list, index) => (
                              <li key={index} style={{fontWeight: 'normal'}}>{list.title}</li>
                          ))}
                          </ul>
                          <p style={{color: '#FFC000'}}>view more</p>
                      </div>
                  </div>
                </div>
              </Link>
            </Col>
            <Col lg={6} className="text-left mt-5 pt-5">
              <h5>{info.title}</h5>
              <p>{info.summary ?? info.description}</p>
            </Col>
          </Row>
        </ScrollAnimation>
      ))}
    </>
  )
}
