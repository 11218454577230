import React, {useState, useEffect} from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import scrollTo from 'gatsby-plugin-smoothscroll';
import Seo from "../components/seo"
import Layout from "../components/layout"
import GlobalJSON from "../content/global.json"
import InnovationJSON from "../content/innovations.json"
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons'
import ScrollAnimation from 'react-animate-on-scroll';
import "../styles/bootstrap.scss"
import "animate.css/animate.min.css";
import HomePortfolios from '../components/home-portfolios'
import MobilePortfolios from '../components/mobile-portfolios'

const DEFAULT_WINDOW_SIZE = 1200
const breakPoint = 990

export default function Index() {
  const [windowWidth, setWindowWidth] = useState(DEFAULT_WINDOW_SIZE)

  useEffect(() => {
    if(typeof window === 'undefined' || !window.document) return
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth))
  }, [])

  return (
    <Layout>
      <Seo title="Home" />
      <img className="parallax home" src="/wireless-city.jpeg" alt="Parallax" />
      <div className="banner-cover home" />
      <Container className="my-0 position-relative text-center d-flex flex-column align-items-center"
            style={{marginTop: '-20px', height: "95vh", justifyContent: "center"}}>
        <ScrollAnimation animateIn="animate__fadeIn" duration={1.5} animateOnce={true}>
          <h2 className="display-4" style={{color: "white", marginBottom: "5rem"}}>{GlobalJSON.header}</h2>
          <Link className="link-button" to="/our-history">LEARN MORE</Link>
        </ScrollAnimation>
        <ScrollAnimation animateIn="animate__fadeIn" duration={1.5} delay={600} animateOnce={true}>
          <FontAwesomeIcon icon={faAngleDoubleDown} id="down-arrow" onClick={() => scrollTo('#content')}/>
        </ScrollAnimation>
      </Container>

      <Container id="content" fluid className="bg-white px-0 pt-4 pb-2 position-relative" style={{boxShadow: 'inset 0 5px 5px #bebebe'}}>
        <Container fluid className="my-0 text-center" style={{padding: '4% 15%'}}>
          <ScrollAnimation animateIn="animate__fadeIn" duration={1.5} animateOnce={true}>
            <Row className='text-left'>
              <Col lg={6}>
                <h3 className='mb-3'>A forward-thinking approach to technology development and services.</h3>
              </Col>
              <Col lg={6}>
                <p>
                TRABUS is a technology company with a mission to connect the globe through disruptive wireless technologies and predictive intelligence.  With technical competencies in wireless communications and artificial intelligence, TRABUS has developed a spectrally efficient peer-to-peer &#40;SEPP&#41; innovation that provides simultaneous wireless conversations on the same frequency without the need for cell towers or infrastructure.  TRABUS also leverages artificial intelligence to enhance wireless and provide predictive analytics for numerous industries, including Maritime.  TRABUS has a growing patent portfolio, was on the Inc. 5000 for seven consecutive years, and was also recognized as 2022 <i>Innovator of the Year</i> in Northern Virginia.
                </p>
              </Col>
            </Row>
          </ScrollAnimation>
        </Container>
      </Container>
      <Container fluid className="bg-light px-0 pb-5 pt-4 position-relative" style={{boxShadow: 'inset 0 5px 5px #bebebe'}}>
        <Container fluid className="my-0 text-center" style={{padding: '4% 15%'}}>
          <ScrollAnimation animateIn="animate__fadeIn" duration={1.5} animateOnce={true}>
            <h6 className=''>
              OUR PORTFOLIOS
              <div className='divider'></div>
            </h6>
            <h5 className="text-center">The <span style={{color: '#FFC000'}}>solution</span> for all your industry needs.</h5>
          </ScrollAnimation>
          <br/>
          {(windowWidth && windowWidth < breakPoint) ? <MobilePortfolios /> : <HomePortfolios />}
          <br/>
        </Container>
      </Container>

      <Container fluid className="bg-white px-0 pb-5 pt-4 position-relative" style={{boxShadow: '0 -5px 5px #bebebe'}}>
        <Container fluid className="my-0 text-center" style={{padding: '4% 15%'}}>
          <ScrollAnimation animateIn="animate__fadeIn" duration={1.5} animateOnce={true}>
            <h6 className=''>
              OUR CONTRIBUTION
              <div className='divider contribution'></div>
            </h6>
            <h5 className="text-center">Featured Innovations</h5>
          </ScrollAnimation>

          <ScrollAnimation animateIn="animate__fadeIn" duration={1.5} animateOnce={true}>
            <div className="container-blur">
              <Row className='justify-content-between'>
                {InnovationJSON.innovations.map((info, key) => (
                  (key < 3 &&
                    <Col className='mb-4' lg={4} key={key}>
                      <Link to={info.link} style={{textDecoration: "none"}}>
                        <div className={'card-blur ' + info.title}>
                          <div className='card-blur-info'>
                            <h5 className="mt-3">{info.title.toUpperCase()}</h5>
                            <p style={{fontSize: 14}}>{info.title === 'WAP' ? info.shortDescription : info.description}</p>
                          </div>
                        </div>
                      </Link>
                    </Col>
                  )
                ))}
              </Row>
            </div>
          </ScrollAnimation>
        </Container>
      </Container>
    </Layout>
  )
}
